import api from './api';
import { endpoints } from '../utils/settings';

export const getAll = async () => {
  return await api.get(endpoints.teamManagement.team);
};

export const getById = async (id) => {
  return await api.get(endpoints.teamManagement.team + '/' + id);
};

export const deleteTeam = async (id) => {
  return await api.delete(`${endpoints.teamManagement.team}/${id}`);
};

export const addTeam = async (payload) => {
  return await api.post(endpoints.teamManagement.team, payload);
};

export const updateTeam = async (id, payload) => {
  return await api.put(`${endpoints.teamManagement.team}/${id}`, payload);
};

const teamApi = { getAll, deleteTeam, addTeam, updateTeam, getById };

export default teamApi;
