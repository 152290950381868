import api from './api';
import { endpoints } from '../utils/settings';

export const getAll = async (query = '') => {
  return await api.get(endpoints.questiontypeManagement.questiontypes + query);
};

export const deleteQuestiontype = async (id) => {
  return await api.delete(
    `${endpoints.questiontypeManagement.questiontypes}/${id}`
  );
};

export const addQuestiontype = async (payload) => {
  return await api.post(
    endpoints.questiontypeManagement.questiontypes,
    payload
  );
};

export const updateQuestiontype = async (id, payload) => {
  return await api.put(
    `${endpoints.questiontypeManagement.questiontypes}/${id}`,
    payload
  );
};

const questiontypeApi = {
  getAll,
  deleteQuestiontype,
  addQuestiontype,
  updateQuestiontype,
};

export default questiontypeApi;
