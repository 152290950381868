import React from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import Login from './views/login/login';
import Layout from './components/layout/layout';
import AuthProvider from './components/authProvider';
import UserManagement from './views/user-management/user-management';
import QuizManagement from './views/quiz-management/quiz-management';
import TeamManagement from './views/team-management/team-management';
import CategoryManagement from './views/category-management/category-management';
import tokenService from './utils/tokenService';
import { useDispatch } from 'react-redux';
import { loginSuccess } from './store/slices/authSlice';
import jwtDecode from 'jwt-decode';
import AddNewQuiz from './views/add-new-quiz/add-new-quiz';
import AddNewTeam from './views/add-new-team/add-new-team';
import EditQuiz from './views/edit-quiz/edit-quiz';
import UsertypeManagement from './views/usertype-management/usertype-management';
import PreviewTeam from './views/preview-team/preview-team';
import PreviewQuiz from './views/preview-quiz/preview-quiz';

const Router = () => {
  const dispatch = useDispatch();
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Login />,
    },
    {
      path: '/dashboard',
      element: (
        <AuthProvider>
          <Layout />
        </AuthProvider>
      ),
      children: [
        {
          path: 'user-management',
          element: <UserManagement />,
        },
        {
          path: 'quiz-management',
          element: <Outlet />,
          children: [
            {
              index: true,
              element: <QuizManagement />,
            },
            {
              path: 'add-new-quiz',
              element: <AddNewQuiz />,
            },
            {
              path: 'edit-quiz',
              element: <EditQuiz />,
            },
            {
              path: ':id',
              element: <PreviewQuiz />,
            },
          ],
        },
        {
          path: 'team-management',
          element: <Outlet />,
          children: [
            {
              index: true,
              element: <TeamManagement />,
            },
            {
              path: 'add-new-team',
              element: <AddNewTeam />,
            },
            {
              path: ':id',
              element: <PreviewTeam />,
            },
          ],
        },
        {
          path: 'category-management',
          element: <CategoryManagement />,
        },
        {
          path: 'usertype-management',
          element: <UsertypeManagement />,
        },
      ],
    },
  ]);

  if (tokenService.getLocalAccessToken()) {
    const user = jwtDecode(tokenService.getLocalAccessToken());
    dispatch(loginSuccess(user));
  }
  return <RouterProvider router={router} />;
};

export default Router;
