import api from './api';
import { endpoints } from '../utils/settings';

export const getAll = async (query = '') => {
  return await api.get(endpoints.usertypeManagement.usertypes + query);
};

export const deleteUsertype = async (id) => {
  return await api.delete(`${endpoints.usertypeManagement.usertypes}/${id}`);
};

export const addUsertype = async (payload) => {
  return await api.post(endpoints.usertypeManagement.usertypes, payload);
};

export const updateUsertype = async (id, payload) => {
  return await api.put(
    `${endpoints.usertypeManagement.usertypes}/${id}`,
    payload
  );
};

const usertypeApi = { getAll, deleteUsertype, addUsertype, updateUsertype };

export default usertypeApi;
