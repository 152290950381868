import { configureStore } from '@reduxjs/toolkit';
//Store Reducers
import authReducer from './slices/authSlice';
import typesReducer from './slices/typesSlice';

const store = configureStore({
  reducer: { authReducer, typesReducer },
});

export default store;
