import React from "react";
import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
const TextInputForm = ({ questionSubmit, setFormQuestions, options }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Form
        style={{ width: "100%" }}
        ref={questionSubmit}
        onFinish={setFormQuestions}
      >
        <Form.Item name="label">
          <Input
            disabled={options.length > 0}
            placeholder={t("QuizManagement.InputText")}
          />
        </Form.Item>

        <Form.Item>
          <Button disabled={options.length > 0} htmlType="submit">
            {t("QuizManagement.AddOption")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TextInputForm;
