import React, { useState, useEffect } from 'react';
import { Button, Spin, Descriptions, Collapse, Divider } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './preview-quiz.css';
import quizApi from '../../services/quizApi';
const { Panel } = Collapse;
const PreviewQuiz = () => {
  const { t, i18n } = useTranslation();
  let { id } = useParams();
  const quizTypesData = useSelector((state) => state.typesReducer.quizTypes);
  const categoryTypesData = useSelector(
    (state) => state.typesReducer.categoryTypes
  );
  const navigate = useNavigate();
  const [data, setData] = useState({
    loading: true,
    quiz: {},
  });

  useEffect(() => {
    const getQuizById = async () => {
      try {
        const res = await quizApi.getById(id);
        setData((prev) => ({ ...prev, quiz: res.data }));
      } catch (error) {
      } finally {
        setData((prev) => ({ ...prev, loading: false }));
      }
    };
    getQuizById();
  }, [id]);
  const { loading, quiz } = data;

  const onClickEdit = async (quiz) => {
    navigate('/dashboard/quiz-management/edit-quiz', { state: data.quiz });
  };

  if (loading)
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Spin />
      </div>
    );
  return (
    <div>
      <div className="title">
        <h1>{t('QuizManagement.QuizDetail')}</h1>
      </div>
      <div>
        <Descriptions
          bordered
          column={{
            md: 4,
            sm: 1,
            xs: 1,
          }}
        >
          <Descriptions.Item label={t('Name')} span={{ md: 2 }}>
            {quiz.title}
          </Descriptions.Item>
          <Descriptions.Item label={t('Code')} span={{ md: 2 }}>
            {quiz.code}
          </Descriptions.Item>
          <Descriptions.Item label={t('Category')} span={{ md: 2 }}>
            {
              categoryTypesData.find((x) => x.id === quiz.categoryId)?.[
                i18n.language === 'en' ? 'name_en' : 'name_tr'
              ]
            }
          </Descriptions.Item>
          <Descriptions.Item
            label={t('QuizManagement.QuizType')}
            span={{ md: 2 }}
          >
            {quizTypesData.find((x) => x.id === quiz.quizTypeId)?.name}
          </Descriptions.Item>
          <Descriptions.Item label={t('Active')} span={{ md: 2 }}>
            {quiz.isActive ? (
              <CheckOutlined style={{ color: '#50C878' }} />
            ) : (
              <CloseOutlined style={{ color: '#ff0a6c' }} />
            )}
          </Descriptions.Item>
          <Descriptions.Item label={t('Point')} span={{ md: 2 }}>
            {quiz.isPoint ? (
              <CheckOutlined style={{ color: '#50C878' }} />
            ) : (
              <CloseOutlined style={{ color: '#ff0a6c' }} />
            )}
          </Descriptions.Item>
          <Descriptions.Item
            label={t('QuizManagement.NumberOfQuestions')}
            span={{ md: 2 }}
          >
            {quiz.numberOfQuestions}
          </Descriptions.Item>
          <Descriptions.Item
            label={t('QuizManagement.Solved')}
            span={{ md: 2 }}
          >
            {quiz.numberOfSolved}
          </Descriptions.Item>
        </Descriptions>
        <div className="title">
          <h1>{t('QuizManagement.Questions')}</h1>
        </div>
        <Collapse>
          {quiz.questions.map((question, i) => (
            <Panel
              header={
                quiz.isPoint ? (
                  <div className="questionHeader">
                    <span>{question.title}</span>
                    <span>
                      {t('QuizManagement.IsPoint') + ':' + question.point}
                    </span>
                  </div>
                ) : (
                  question.title
                )
              }
              key={i}
            >
              {question.options.map((opt, ind) => (
                <div key={ind}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>{opt.label}</span>
                    {opt.isCorrect ? (
                      <CheckOutlined style={{ color: '#06c006' }} />
                    ) : (
                      <CloseOutlined style={{ color: '#ed4061' }} />
                    )}
                  </div>
                  <Divider className="divider" />
                </div>
              ))}
            </Panel>
          ))}
        </Collapse>
      </div>
      <div className="edit-btn-container">
        <Button key={1} type="primary" onClick={onClickEdit}>
          {t('Edit')}
        </Button>
      </div>
    </div>
  );
};

export default PreviewQuiz;
