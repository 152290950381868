import React, { useState, useEffect } from 'react';
import { Button, Spin, Table, Descriptions } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import teamApi from '../../services/teamApi';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './preview-team.css';
const PreviewTeam = () => {
  const { t, i18n } = useTranslation();
  let { id } = useParams();
  const quizTypesData = useSelector((state) => state.typesReducer.quizTypes);
  const categoryTypesData = useSelector(
    (state) => state.typesReducer.categoryTypes
  );
  const columns = [
    {
      title: t('Username'),
      dataIndex: 'username',
      key: 'username',
      //render: (text) => <a>{text}</a>,
    },
  ];
  const quizColumns = [
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'name',
    },
    {
      title: t('QuizManagement.QuizType'),
      dataIndex: 'quizType',
      key: 'quizType',
      filters: quizTypesData.map((x) => ({ text: x.name, value: x.id })),
      onFilter: (value, record) => record.quizTypeId === value,
      render: (val) => val.name,
    },
    {
      title: t('QuizManagement.CategoryType'),
      dataIndex: 'category',
      key: 'categoryType',
      filters: categoryTypesData.map((x) => ({
        text: i18n.language === 'en' ? x?.name_en : x?.name_tr,
        value: x.id,
      })),
      onFilter: (value, record) => record.category.id === value,
      render: (val) =>
        categoryTypesData.find((x) => x.id === val.id)?.[
          i18n.language === 'en' ? 'name_en' : 'name_tr'
        ],
    },
    {
      title: t('Actions'),
      key: 'id',
      render: (val) => (
        <Link to={'/dashboard/quiz-management/' + val.id}>
          <EyeOutlined style={{ color: '#2d27ff' }} />
        </Link>
      ),
    },
  ];

  const [data, setData] = useState({
    loading: true,
    team: {},
  });

  useEffect(() => {
    const getTeamById = async () => {
      try {
        const res = await teamApi.getById(id);
        setData((prev) => ({ ...prev, team: res.data }));
      } catch (error) {
      } finally {
        setData((prev) => ({ ...prev, loading: false }));
      }
    };
    getTeamById();
  }, [id]);

  const { loading, team } = data;

  if (loading)
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Spin />
      </div>
    );
  return (
    <div>
      <div className="title">
        <h1>{t('TeamManagement.TeamDetail')}</h1>
      </div>
      <div>
        <Descriptions
          bordered
          column={{
            md: 4,
            sm: 1,
            xs: 1,
          }}
        >
          <Descriptions.Item label={t('Name')} span={{ md: 2 }}>
            {team.name}
          </Descriptions.Item>
          <Descriptions.Item
            label={t('TeamManagement.TeamOwner')}
            span={{ md: 2 }}
          >
            {team.owner.username}
          </Descriptions.Item>
          <Descriptions.Item label={t('TeamManagement.Users')} span={{ md: 4 }}>
            <Table
              bordered
              rowKey="id"
              loading={loading}
              columns={columns}
              dataSource={team.users}
            />
          </Descriptions.Item>
          <Descriptions.Item
            label={t('TeamManagement.Quizzes')}
            span={{ md: 4 }}
          >
            <Table
              bordered
              rowKey="id"
              loading={loading}
              columns={quizColumns}
              dataSource={team.quizzes}
            />
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div className="edit-btn-container">
        <Link key={1} to={'add-new-team'}>
          <Button key={1} type="primary">
            {t('Edit')}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default PreviewTeam;
