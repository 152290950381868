import React, { useState, useRef } from 'react';
import { Input, Select, Button, Table } from 'antd';
import { createGuid } from '../../../utils/helper';
import RadioForm from './forms/radio-form';
import CheckboxForm from './forms/checkbox-form';
import BooleanForm from './forms/boolean-form';
import TextInputForm from './forms/text-input-form';
import {
  DoubleLeftOutlined,
  LeftOutlined,
  RightOutlined,
  DoubleRightOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const Question = ({
  onClickNextQuestion,
  onClickFinish,
  onClickPrevious,
  onClickPreviousQuestion,
  node,
  current,
  quiz,
}) => {
  const { t } = useTranslation();
  const questionTypesData = useSelector(
    (state) => state.typesReducer.questionTypes
  );

  const booleanId = questionTypesData.find((x) => x.name === 'Boolean')?.id;
  const radioId = questionTypesData.find((x) => x.name === 'Radio')?.id;
  const checkboxId = questionTypesData.find((x) => x.name === 'Checkbox')?.id;
  const textInputId = questionTypesData.find((x) => x.name === 'TextInput')?.id;
  const questionSubmit = useRef();
  const [question, setQuestion] = useState({
    questionTypeId: node?.questionTypeId || null,
    title: node?.title || '',
    options: node?.options || [],
    point: node?.point || '',
  });

  const onClickSave = () => {
    onClickNextQuestion(question);
    setQuestion({
      questionTypeId: null,
      title: '',
      options: [],
      point: '',
    });
  };

  const setFormQuestions = (values) => {
    if (questionTypeId === radioId || questionTypeId === checkboxId) {
      setQuestion((prev) => ({
        ...prev,
        options: [
          ...prev.options,
          {
            id: createGuid(),
            label: values.label,
            value: values.label,
            isCorrect: values.correct,
          },
        ],
      }));
    } else if (questionTypeId === booleanId) {
      setQuestion((prev) => ({
        ...prev,
        options: [
          {
            id: createGuid(),
            label: 'Yes',
            value: 'Yes',
            isCorrect: values.isTrueFalse === 'Yes' ? true : false,
          },
          {
            id: createGuid(),
            label: 'No',
            value: 'No',
            isCorrect: values.isTrueFalse === 'No' ? true : false,
          },
        ],
      }));
    } else {
      setQuestion((prev) => ({
        ...prev,
        options: [
          ...prev.options,
          {
            id: createGuid(),
            label: values.label,
            value: values.label,
            isCorrect: true,
          },
        ],
      }));
    }

    questionSubmit.current.resetFields();
  };

  const deleteChoice = (id) => {
    const newoptions = question.options.filter((x) => x.id !== id);
    if (question.questionTypeId !== booleanId) {
      setQuestion((prev) => ({ ...prev, options: newoptions }));
    } else {
      setQuestion((prev) => ({ ...prev, options: [] }));
    }
  };
  const columns = [
    {
      title: t('QuizManagement.OptionName'),
      dataIndex: 'label',
      key: 'id',
      //render: (text) => <a>{text}</a>,
    },
    {
      title: t('QuizManagement.CorrectOption'),
      dataIndex: 'isCorrect',
      key: 'id',
      width: '20%',
      align: 'center',
      render: (_, record) =>
        record.isCorrect ? (
          <CheckCircleOutlined style={{ color: '#06c006' }} />
        ) : (
          <CloseCircleOutlined style={{ color: '#ed4061' }} />
        ),
    },
    {
      title: t('Actions'),
      key: 'id',
      width: '20%',
      align: 'center',
      render: (_, record) => (
        <DeleteOutlined
          onClick={() => deleteChoice(record.id)}
          style={{ color: '#ed4061' }}
        />
      ),
    },
  ];
  const { questionTypeId, title, options, point } = question;
  return (
    <div className="question-container" style={{ padding: 12 }}>
      <div>
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            icon={<DoubleLeftOutlined />}
            type="primary"
            onClick={() => onClickPrevious()}
          >
            {t('QuizManagement.GoBackToQuiz')}
          </Button>

          <Button
            icon={<LeftOutlined />}
            type="primary"
            disabled={current === 0}
            onClick={onClickPreviousQuestion}
          >
            {t('QuizManagement.PreviousQuestion')}
          </Button>

          <Button
            disabled={
              options.length === 0 ||
              !options.find((x) => x.isCorrect) ||
              (quiz.isPoint && !point)
            }
            type="primary"
            onClick={() => onClickSave()}
          >
            {t('QuizManagement.SaveNextQuestion')}
            <RightOutlined />
          </Button>

          <Button
            disabled={
              options.length === 0 ||
              !options.find((x) => x.isCorrect) ||
              (quiz.isPoint ? !point : false)
            }
            style={{
              backgroundColor:
                options.length > 0 &&
                options.find((x) => x.isCorrect) &&
                (quiz.isPoint ? point : true) &&
                '#50C878',
              color:
                options.length > 0 &&
                options.find((x) => x.isCorrect) &&
                (quiz.isPoint ? point : true) &&
                'white',
            }}
            onClick={() => onClickFinish(question)}
          >
            {t('QuizManagement.CompleteQuestions')}
            <DoubleRightOutlined />
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          {quiz.isPoint && (
            <div style={{ marginBottom: 10, width: '48%' }}>
              <Input
                value={point}
                onChange={(val) =>
                  setQuestion((prev) => ({
                    ...prev,
                    point: val.target.value,
                  }))
                }
                placeholder={t('QuizManagement.IsPoint')}
              />
            </div>
          )}
          <Select
            value={questionTypeId}
            disabled={options.length > 0}
            style={{ marginBottom: 10, width: '48%' }}
            placeholder={t('QuizManagement.SelectQuestionType')}
            onChange={(val) =>
              setQuestion((prev) => ({ ...prev, questionTypeId: val }))
            }
          >
            {questionTypesData.map((x, i) => (
              <Select.Option key={i} value={x.id}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
          {questionTypeId !== null && (
            <div style={{ marginBottom: 10, width: '48%' }}>
              <Input
                onChange={(val) =>
                  setQuestion((prev) => ({ ...prev, title: val.target.value }))
                }
                disabled={options.length > 0}
                placeholder={t('QuizManagement.InputQuestionTitle')}
              />
            </div>
          )}

          {title !== '' && questionTypeId === radioId ? (
            <RadioForm
              questionSubmit={questionSubmit}
              setFormQuestions={setFormQuestions}
              options={options}
            />
          ) : questionTypeId === checkboxId ? (
            <CheckboxForm
              questionSubmit={questionSubmit}
              setFormQuestions={setFormQuestions}
              options={options}
            />
          ) : questionTypeId === booleanId ? (
            <BooleanForm
              questionSubmit={questionSubmit}
              setFormQuestions={setFormQuestions}
            />
          ) : questionTypeId === textInputId ? (
            <TextInputForm
              questionSubmit={questionSubmit}
              setFormQuestions={setFormQuestions}
              options={options}
            />
          ) : null}

          <div
            style={{
              width: '100%',
              minHeight: '20vh',
              marginTop: 20,
            }}
          >
            <Table
              style={{ width: '100%' }}
              bordered
              rowKey="id"
              columns={columns}
              dataSource={options}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question;
