import React, { useState, useRef } from 'react';
import { Steps } from 'antd';
import QuestionStack from './questions/question-stack';
import Quiz from './quiz/quiz';
import './edit-quiz.css';
import { useTranslation } from 'react-i18next';
import Save from './final/save';
import { useLocation } from 'react-router-dom';

const { Step } = Steps;
const EditQuiz = () => {
  const { t } = useTranslation();
  const quizSubmit = useRef();
  const [current, setCurrent] = useState(0);
  const location = useLocation();

  const [quiz, setQuiz] = useState(location.state);
  const addQuiz = (values) => {
    setQuiz((prev) => ({ ...prev, ...values }));
    setCurrent(current + 1);
  };
  const next = () => {
    quizSubmit.current.submit();
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const finishQuiz = (val) => {
    setQuiz((prev) => ({ ...prev, questions: val }));
    setCurrent(steps.length - 1);
  };

  const steps = [
    {
      title: t('QuizManagement.Quiz'),
      content: (
        <Quiz
          addQuiz={addQuiz}
          quizSubmit={quizSubmit}
          onClickNext={next}
          node={quiz}
          key={current}
        />
      ),
    },
    {
      title: t('QuizManagement.Questions'),
      content: (
        <QuestionStack
          questions={quiz.questions}
          finishQuiz={finishQuiz}
          onClickPrevious={prev}
          quiz={quiz}
        />
      ),
    },
    {
      title: t('Save'),
      content: <Save quiz={quiz} />,
    },
  ];
  return (
    <div>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
    </div>
  );
};

export default EditQuiz;
