import api from './api';
import { endpoints } from '../utils/settings';

export const getAll = async (query = '') => {
  return await api.get(endpoints.categoryManagement.category + query);
};

export const deleteCategory = async (id) => {
  return await api.delete(`${endpoints.categoryManagement.category}/${id}`);
};

export const addCategory = async (payload) => {
  return await api.post(endpoints.categoryManagement.category, payload);
};

export const updateCategory = async (id, payload) => {
  return await api.put(
    `${endpoints.categoryManagement.category}/${id}`,
    payload
  );
};

const categoryApi = { getAll, deleteCategory, addCategory, updateCategory };

export default categoryApi;
