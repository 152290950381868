import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Transfer } from 'antd';
import { useTranslation } from 'react-i18next';
import './add-new-team.css';
import userApi from '../../services/userApi';
import quizApi from '../../services/quizApi';
import teamApi from '../../services/teamApi';
import { useNavigate } from 'react-router-dom';
const { Option } = Select;

const AddNewTeam = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [state, setState] = useState({ users: [], quizzes: [], loading: true });
  const [targetKeys, setTargetKeys] = useState({
    usersKey: [],
    quizzesKey: [],
  });
  const onFinish = async (payload) => {
    const { quizIds, userIds } = payload;
    const object = {
      ...payload,
      quizIds: quizIds.map((quizId) => quizId),
      userIds: userIds.map((userId) => userId),
    };
    try {
      const res = await teamApi.addTeam(object);
      if (res.status === 200) {
        navigate('/dashboard/team-management');
      }
    } catch (error) {}
  };
  useEffect(() => {
    const getUsersAndQuizzes = async () => {
      try {
        const userRes = await userApi.getAll();
        const quizRes = await quizApi.getAll();
        setState((prev) => ({
          ...prev,
          users: userRes.data.rows,
          quizzes: quizRes.data.rows,
        }));
      } catch (error) {
      } finally {
        setState((prev) => ({ ...prev, loading: false }));
      }
    };
    getUsersAndQuizzes();
  }, []);
  const handleChange = (newTargetKeys, key) => {
    setTargetKeys((prev) => ({ ...prev, [key]: newTargetKeys }));
  };

  const renderItem = (item, key) => {
    const customLabel = <span className="custom-item">{item[key]}</span>;
    return {
      label: customLabel,
      value: item.id,
    };
  };

  return (
    <div className="add-team-container">
      <Form
        name="normal_login"
        className="add-team-form"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          name="name"
          label={t('TeamManagement.TeamName')}
          rules={[
            {
              required: true,
              message: t('Errors.InputTeamName'),
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="ownerId"
          label={t('TeamManagement.TeamOwner')}
          rules={[
            {
              required: true,
              message: t('Errors.SelectTeamOwner'),
            },
          ]}
        >
          <Select size="large">
            {state.users.map((user) => (
              <Option key={user.id} value={user.id}>
                {user.username}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="userIds"
          label={t('TeamManagement.Users')}
          rules={[
            {
              required: false,
              message: t('Errors.AddUsers'),
            },
          ]}
        >
          <Transfer
            rowKey={(record) => record.id}
            dataSource={state.users}
            listStyle={{
              width: 300,
              height: 300,
            }}
            showSearch
            filterOption={(val, option) =>
              option.username.toLowerCase().includes(val.toLowerCase())
            }
            targetKeys={targetKeys.usersKey}
            onChange={(val) => handleChange(val, 'usersKey')}
            render={(item) => renderItem(item, 'username')}
          />
        </Form.Item>
        <Form.Item
          name="quizIds"
          label={t('TeamManagement.Quizzes')}
          rules={[
            {
              required: false,
              message: t('Errors.AddQuizzes'),
            },
          ]}
        >
          <Transfer
            rowKey={(record) => record.id}
            dataSource={state.quizzes}
            listStyle={{
              width: 300,
              height: 300,
            }}
            showSearch
            filterOption={(val, option) =>
              option.title.toLowerCase().includes(val.toLowerCase())
            }
            targetKeys={targetKeys.quizzesKey}
            onChange={(val) => handleChange(val, 'quizzesKey')}
            render={(item) => renderItem(item, 'title')}
          />
        </Form.Item>
        <Form.Item
          style={{
            display: 'inline-flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Button
            htmlType="submit"
            className="team-form-button"
            size="large"
            //loading={isLoading}
            //disabled={isLoading}
          >
            {t('Save')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddNewTeam;
