import api from './api';
import { endpoints } from '../utils/settings';

export const getAll = async () => {
  return await api.get(endpoints.quizManagement.quiz);
};

export const getById = async (id) => {
  return await api.get(endpoints.quizManagement.quiz + '/' + id);
};

export const addQuiz = async (payload) => {
  return await api.post(endpoints.quizManagement.quiz, payload);
};

export const updateQuiz = async (payload) => {
  return await api.put(
    `${endpoints.quizManagement.quiz}/${payload.id}`,
    payload
  );
};

export const deleteQuiz = async (id) => {
  return await api.delete(`${endpoints.quizManagement.quiz}/${id}`);
};

const quizApi = { getAll, addQuiz, deleteQuiz, updateQuiz, getById };

export default quizApi;
