import React, { useState, useEffect } from 'react';
import { Button, Space, Table, Modal, Form, Input, Select } from 'antd';
import userApi from '../../services/userApi';
import './user-management.css';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { userTypes } from '../../utils/settings';
import { useSelector } from 'react-redux';
const { Option } = Select;
const UserManagement = () => {
  const { t } = useTranslation();
  const userTypesData = useSelector((state) => state.typesReducer.userTypes);

  const onDelete = async (id) => {
    try {
      await userApi.deleteUser(id);
      const res = await userApi.getAll();
      setData((prev) => ({ ...prev, users: res.data.rows }));
    } catch (error) {}
  };

  const onEdit = (record) => {
    form.setFieldsValue({ ...record });
    setData((prev) => ({
      ...prev,
      isEditing: true,
      id: record.id,
      popupTitle: t('UserManagement.EditUser'),
      openPopup: true,
    }));
  };

  const [form] = Form.useForm();

  const [data, setData] = useState({
    loading: true,
    users: [],
    usertypes: [],
    openPopup: false,
    isEditing: false,
    isUserAdding: false,
    id: null,
    popupTitle: t('UserManagement.AddNewUser'),
  });

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await userApi.getAll();
        setData((prev) => ({ ...prev, users: res.data.rows }));
      } catch (error) {
      } finally {
        setData((prev) => ({ ...prev, loading: false }));
      }
    };
    getUsers();
  }, []);

  const openPopup = () => {
    setData((prev) => ({
      ...prev,
      popupTitle: t('UserManagement.AddNewUser'),
      openPopup: true,
    }));
  };

  const onFinish = async (payload) => {
    try {
      setData((prev) => ({ ...prev, isUserAdding: true }));
      if (data.isEditing) {
        await userApi.updateUser(data.id, payload);
      } else {
        await userApi.addUser(payload);
      }

      const res = await userApi.getAll();
      setData((prev) => ({ ...prev, users: res.data.rows }));
    } catch (error) {
    } finally {
      setData((prev) => ({
        ...prev,
        openPopup: false,
        isEditing: false,
        isUserAdding: false,
      }));
      form.resetFields();
    }
  };
  const columns = [
    {
      title: t('Username'),
      dataIndex: 'username',
      key: 'username',
      //render: (text) => <a>{text}</a>,
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('UserType'),
      dataIndex: 'userType',
      key: 'userType',
      filters: userTypesData.map((x) => ({ text: x.name, value: x.id })),
      onFilter: (value, record) => record.userType.id === value,
      render: (rec) => rec.name,
    },
    {
      title: t('Rank'),
      dataIndex: 'rank',
      key: 'rank',
    },
    {
      title: t('Actions'),
      key: 'id',
      render: (_, record) =>
        record.userType.code !== userTypes.admin && (
          <Space size="middle">
            <EditOutlined
              onClick={() => onEdit(record)}
              style={{ color: '#2d27ff' }}
            />
            <DeleteOutlined
              onClick={() => onDelete(record.id)}
              style={{ color: '#ff0a6c' }}
            />{' '}
          </Space>
        ),
    },
  ];

  return (
    <div>
      <div className="page-header">
        <Button
          key={1}
          icon={<PlusCircleOutlined />}
          type="primary"
          onClick={openPopup}
        >
          {t('UserManagement.AddNewUser')}
        </Button>
      </div>
      <Table
        bordered
        rowKey="id"
        loading={data.loading}
        columns={columns}
        dataSource={data.users}
      />
      <Modal
        title={data.popupTitle}
        centered
        open={data.openPopup}
        footer={null}
        onCancel={() => {
          form.resetFields();
          setData((prev) => ({
            ...prev,
            openPopup: false,
            isEditing: false,
          }));
        }}
      >
        <Form
          name="normal_login"
          className="login-form"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
            ]}
          >
            <Input placeholder={t('Username')} size="large" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
            ]}
          >
            <Input placeholder="E-mail" size="large" />
          </Form.Item>
          <Form.Item
            name="userTypeId"
            rules={[
              {
                required: true,
                message: 'Please select user type!',
              },
            ]}
          >
            <Select placeholder={t('UserType')} size="large">
              {userTypesData.map((type, i) => (
                <Option key={i} value={type.id}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input type="password" placeholder={t('Password')} size="large" />
          </Form.Item>

          <Form.Item style={{ textAlign: 'center' }}>
            <Button
              htmlType="submit"
              className="login-form-button"
              size="large"
              loading={data.isUserAdding}
              disabled={data.isUserAdding}
            >
              {t('Save')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserManagement;
