import React from 'react';
import { Breadcrumb as BreadcrumbAnt } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './breadcrumb.css';
const Breadcrumb = () => {
  const { t } = useTranslation();
  let { pathname } = useLocation();
  const urls = pathname.split('/').filter((x) => x);

  const items = urls.map((x, ind) => {
    let link = '';

    for (let index = 0; index <= ind; index++) {
      link += '/' + urls[index];
    }

    return {
      title:
        ind === urls.length - 1 ? (
          <span key={ind} className="breadcrumb-item">
            {t('Urls.' + x)}
          </span>
        ) : (
          <Link key={ind} className="breadcrumb-item" to={link}>
            {t('Urls.' + x)}
          </Link>
        ),
    };
  });
  return <BreadcrumbAnt items={items} />;
};

export default Breadcrumb;
