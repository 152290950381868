import React, { useState } from 'react';
import { Steps } from 'antd';
import Question from './question';
import './style.css';

const { Step } = Steps;
const QuestionStack = ({ finishQuiz, onClickPrevious, quiz }) => {
  const [state, setState] = useState({ questions: [], current: 0 });

  const next = (val) => {
    if (state.questions[state.current] !== undefined) {
      state.questions[state.current] = val;
      if (steps.length - 1 < state.current + 1) {
        setSteps((prev) => [
          ...prev,
          {
            title: '',
            content: null,
          },
        ]);
      }

      setState((prev) => ({
        ...prev,
        questions: state.questions,
        current: state.current + 1,
      }));
      return;
    }
    if (steps.length - 1 >= state.current + 1) {
      setState((prev) => ({
        ...prev,
        current: state.current + 1,
      }));
      return;
    }
    setSteps((prev) => [
      ...prev,
      {
        title: '',
        content: null,
      },
    ]);
    setState((prev) => ({
      ...prev,
      questions: [...state.questions, val],
      current: state.current + 1,
    }));
  };
  const finish = (val) => {
    const allQuest = [...state.questions, val];
    finishQuiz(allQuest);
  };
  const [steps, setSteps] = useState([
    {
      title: '',
    },
  ]);

  const prev = () => {
    setState((prev) => ({
      ...prev,
      current: state.current - 1 < 0 ? 0 : state.current - 1,
    }));
  };

  return (
    <div style={{ padding: 12 }}>
      <Steps current={state.current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <Question
        onClickNextQuestion={next}
        onClickFinish={finish}
        onClickPrevious={onClickPrevious}
        onClickPreviousQuestion={prev}
        node={state.questions[state.current]}
        key={state.current}
        current={state.current}
        quiz={quiz}
      />
      <div className="steps-action">
        {/* {state.current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => message.success("Processing complete!")}
          >
            Done
          </Button>
        )} */}
      </div>
    </div>
  );
};

export default QuestionStack;
