import api from "./api";
import { endpoints } from "../utils/settings";

export const getAll = async (query = "") => {
  return await api.get(endpoints.userManagement.users + query);
};

export const deleteUser = async (id) => {
  return await api.delete(`${endpoints.userManagement.users}/${id}`);
};

export const addUser = async (payload) => {
  return await api.post(endpoints.userManagement.users, payload);
};

export const updateUser = async (id, payload) => {
  return await api.put(`${endpoints.userManagement.users}/${id}`, payload);
};

const userApi = { getAll, deleteUser, addUser, updateUser };

export default userApi;
