import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import questiontypeApi from '../services/questiontypeApi';
import {
  setCategoryTypes,
  setQuestionTypes,
  setQuizTypes,
  setUserTypes,
} from '../store/slices/typesSlice';
import quiztypeApi from '../services/quiztypeApi';
import categoryApi from '../services/categoryApi';
import usertypeApi from '../services/usertypeApi';
const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  let location = useLocation();
  const auth = useSelector((state) => state.authReducer.isAuthenticated);

  const getQuestiontypes = async () => {
    try {
      const res = await questiontypeApi.getAll();
      dispatch(setQuestionTypes(res.data.rows));
    } catch (error) {}
  };
  const getQuiztypes = async () => {
    try {
      const res = await quiztypeApi.getAll();
      dispatch(setQuizTypes(res.data.rows));
    } catch (error) {}
  };
  const getCategorytypes = async () => {
    try {
      const res = await categoryApi.getAll();
      dispatch(setCategoryTypes(res.data.rows));
    } catch (error) {}
  };
  const getUsertypes = async () => {
    try {
      const res = await usertypeApi.getAll();
      dispatch(setUserTypes(res.data.rows));
    } catch (error) {}
  };

  if (!auth) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    getQuestiontypes();
    getQuiztypes();
    getCategorytypes();
    getUsertypes();
  }

  return children;
};

export default AuthProvider;
