import React from "react";
import { Button, Form, Input, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
const RadioForm = ({ questionSubmit, setFormQuestions, options }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Form
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
        ref={questionSubmit}
        onFinish={setFormQuestions}
      >
        <Form.Item name="label" style={{ width: "48%" }}>
          <Input placeholder={t("QuizManagement.InputOptionTitle")} />
        </Form.Item>
        <Form.Item
          initialValue={false}
          name="correct"
          valuePropName="checked"
          style={{ width: "48%" }}
        >
          <Checkbox disabled={options.find((x) => x.isCorrect)}>
            {t("QuizManagement.CorrectOption")}
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit">{t("QuizManagement.AddOption")}</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RadioForm;
