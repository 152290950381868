import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store/store';
import { ConfigProvider } from 'antd';

import './locales/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: '#ff0a6c',
          borderRadius: 4,
        },
      }}
    >
      <App />
    </ConfigProvider>
  </Provider>
);
