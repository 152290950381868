import React, { useState, useEffect } from 'react';
import { Button, Space, Table, Modal, Form, Input } from 'antd';
import usertypeApi from '../../services/usertypeApi';
import './usertype-management.css';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { userTypes } from '../../utils/settings';

const UsertypeManagement = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('Code'),
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('Actions'),
      key: 'id',
      render: (_, record) =>
        record.code !== userTypes.admin && (
          <Space size="middle">
            <EditOutlined
              onClick={() => onEdit(record)}
              style={{ color: '#2d27ff' }}
            />
            <DeleteOutlined
              onClick={() => onDelete(record.id)}
              style={{ color: '#ff0a6c' }}
            />{' '}
          </Space>
        ),
    },
  ];

  const onDelete = async (id) => {
    try {
      await usertypeApi.deleteUsertype(id);
      const res = await usertypeApi.getAll();
      setData((prev) => ({ ...prev, usertypes: res.data.rows }));
    } catch (error) {}
  };

  const onEdit = (record) => {
    form.setFieldsValue({ ...record });
    setData((prev) => ({
      ...prev,
      isEditing: true,
      id: record.id,
      popupTitle: t('UserManagement.EditUser'),
      openPopup: true,
    }));
  };

  const [form] = Form.useForm();

  const [data, setData] = useState({
    loading: true,
    usertypes: [],
    openPopup: false,
    isEditing: false,
    isUsertypesAdding: false,
    id: null,
    popupTitle: t('UsertypeManagement.AddNewUsertype'),
  });

  useEffect(() => {
    const getUsertypes = async () => {
      try {
        const res = await usertypeApi.getAll();
        setData((prev) => ({ ...prev, usertypes: res.data.rows }));
      } catch (error) {
      } finally {
        setData((prev) => ({ ...prev, loading: false }));
      }
    };
    getUsertypes();
  }, []);

  const openPopup = () => {
    setData((prev) => ({
      ...prev,
      popupTitle: t('UsertypeManagement.AddNewUsertype'),
      openPopup: true,
    }));
  };

  const onFinish = async (payload) => {
    try {
      setData((prev) => ({ ...prev, isUsertypesAdding: true }));
      if (data.isEditing) {
        await usertypeApi.updateUsertype(data.id, payload);
      } else {
        await usertypeApi.addUsertype(payload);
      }

      const res = await usertypeApi.getAll();
      setData((prev) => ({ ...prev, usertypes: res.data.rows }));
    } catch (error) {
    } finally {
      setData((prev) => ({
        ...prev,
        openPopup: false,
        isEditing: false,
        isUsertypesAdding: false,
      }));
      form.resetFields();
    }
  };

  return (
    <div>
      <div className="page-header">
        <Button
          key={1}
          icon={<PlusCircleOutlined />}
          type="primary"
          onClick={openPopup}
        >
          {t('UsertypeManagement.AddNewUsertype')}
        </Button>
      </div>
      <Table
        bordered
        rowKey="id"
        loading={data.loading}
        columns={columns}
        dataSource={data.usertypes}
      />
      <Modal
        title={data.popupTitle}
        centered
        open={data.openPopup}
        footer={null}
        onCancel={() => {
          form.resetFields();
          setData((prev) => ({
            ...prev,
            openPopup: false,
            isEditing: false,
          }));
        }}
      >
        <Form
          name="normal_login"
          className="login-form"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input turkish title!',
              },
            ]}
          >
            <Input placeholder={t('Name')} size="large" />
          </Form.Item>
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: 'Please input turkish title!',
              },
            ]}
          >
            <Input placeholder={t('Code')} size="large" />
          </Form.Item>

          <Form.Item style={{ textAlign: 'center' }}>
            <Button
              htmlType="submit"
              className="login-form-button"
              size="large"
              loading={data.isUsertypesAdding}
              disabled={data.isUsertypesAdding}
            >
              {t('Save')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UsertypeManagement;
