import api from './api';
import { endpoints } from '../utils/settings';

export const getAll = async (query = '') => {
  return await api.get(endpoints.quiztypeManagement.quiztypes + query);
};

export const deleteQuiztype = async (id) => {
  return await api.delete(`${endpoints.quiztypeManagement.quiztypes}/${id}`);
};

export const addQuiztype = async (payload) => {
  return await api.post(endpoints.quiztypeManagement.quiztypes, payload);
};

export const updateQuiztype = async (id, payload) => {
  return await api.put(
    `${endpoints.quiztypeManagement.quiztypes}/${id}`,
    payload
  );
};

const quiztypeApi = {
  getAll,
  deleteQuiztype,
  addQuiztype,
  updateQuiztype,
};

export default quiztypeApi;
