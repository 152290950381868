import { notification } from "antd";
export const displayNotf = (type, title, desc, placement) => {
  notification[type]({
    message: title,
    //description: desc,
    placement,
  });
};

export const createGuid = () => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4();
};
