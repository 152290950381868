import React, { useState } from 'react';
import './login.css';
import { Button, Form, Input } from 'antd';
import { LockOutlined, UserOutlined, LoginOutlined } from '@ant-design/icons';
import api from '../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess } from '../../store/slices/authSlice';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { endpoints, userTypes } from '../../utils/settings';
import { displayNotf } from '../../utils/helper';
import tokenService from '../../utils/tokenService';
const Login = () => {
  let location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const auth = useSelector((state) => state.authReducer.isAuthenticated);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const res = await api.post(endpoints.auth.login, values);
      if (
        res.status === 200 &&
        res.data.user.userType.code === userTypes.admin
      ) {
        tokenService.updateLocalAccessToken(res.data.accessToken);
        dispatch(loginSuccess(res.data.user));
        navigate('/dashboard');
      } else {
        displayNotf('error', 'Restricted User', 'hata', 'topRight');
      }
    } catch (err) {
      displayNotf('error', 'Login failed', 'hata', 'topRight');
    } finally {
      setIsLoading(false);
    }
  };

  if (auth) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }
  return (
    <div className="main">
      <div className="login-container">
        <h2>Taria Admin Login</h2>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              size="large"
            />
          </Form.Item>

          <Form.Item style={{ textAlign: 'center' }}>
            <Button
              htmlType="submit"
              className="login-form-button"
              size="large"
              loading={isLoading}
              disabled={isLoading}
            >
              Login
              <LoginOutlined />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
