import React from "react";
import { Button, Form, Radio } from "antd";
import { useTranslation } from "react-i18next";
const BooleanForm = ({ questionSubmit, setFormQuestions }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Form
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
        ref={questionSubmit}
        onFinish={setFormQuestions}
      >
        <Form.Item
          label={t("QuizManagement.IsCorrect")}
          name={"isTrueFalse"}
          style={{
            width: "100%",
          }}
        >
          <Radio.Group>
            <Radio value={"Yes"}>{t("Yes")}</Radio>
            <Radio value={"No"}>{t("No")}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit">{t("QuizManagement.AddOption")}</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default BooleanForm;
