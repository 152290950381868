import React, { useState, useEffect } from 'react';
import { Form, Input, Radio, Select, Switch, Button, Spin } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import userApi from '../../../services/userApi';
import categoryApi from '../../../services/categoryApi';
import { useSelector } from 'react-redux';
const Quiz = ({ quizSubmit, addQuiz, onClickNext, node }) => {
  const { t, i18n } = useTranslation();
  const quizTypesData = useSelector((state) => state.typesReducer.quizTypes);
  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await userApi.getAll();
        setState((prev) => ({ ...prev, users: res.data.rows }));
      } catch (error) {
      } finally {
        setState((prev) => ({ ...prev, loading: false }));
      }
    };
    const getCategories = async () => {
      try {
        const res = await categoryApi.getAll('?isActive=true');
        setState((prev) => ({ ...prev, categories: res.data.rows }));
      } catch (error) {
        setState((prev) => ({ ...prev, loading: false }));
      }
    };
    getUsers();
    getCategories();
  }, []);

  const [state, setState] = useState({
    users: [],
    categories: [],
    loading: true,
  });

  const [quiz, setQuiz] = useState({
    categoryId: node?.categoryId || null,
    isActive: node?.isActive || false,
    ownerId: node?.ownerId || null,
    quizTypeId: node?.quizTypeId || null,
    title: node?.title || null,
    questions: node?.questions || [],
    code: node?.code || null,
    isPoint: node?.isPoint || false,
  });
  const [quizType, setQuizType] = useState(
    quizTypesData.find((x) => x.name === 'Default')?.id
  );
  const onFinish = (values) => {
    setQuiz(values);
    addQuiz(values);
  };

  if (state.loading)
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Spin />
      </div>
    );
  return (
    <div style={{ padding: 12, width: '100%' }}>
      <Form
        ref={quizSubmit}
        initialValues={quiz}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 12,
        }}
        layout="horizontal"
        size={'large'}
        onFinish={onFinish}
      >
        <Form.Item
          label={t('Title')}
          name={'title'}
          rules={[
            {
              required: true,
              message: t('Errors.InputTitle'),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('QuizManagement.QuizType')}
          name="quizTypeId"
          rules={[
            {
              required: true,
              message: t('Errors.SelectQuizType'),
            },
          ]}
        >
          <Radio.Group onChange={(val) => setQuizType(val.target.value)}>
            {quizTypesData.map((x, i) => (
              <Radio.Button key={i} value={x.id}>
                {x.name}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        {quizType !== quizTypesData.find((x) => x.name === 'Default')?.id && (
          <Form.Item
            label={t('QuizManagement.QuizOwner')}
            name={'ownerId'}
            rules={[
              {
                required: true,
                message: t('Errors.SelectOwner'),
              },
            ]}
          >
            <Select>
              {state.users.map((user, i) => (
                <Select.Option key={i} value={user.id}>
                  {user.username}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {quizType === quizTypesData.find((x) => x.name === 'Private')?.id && (
          <Form.Item
            label={t('Code')}
            name={'code'}
            rules={[
              {
                required: true,
                message: t('Errors.EnterCode'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          label={t('QuizManagement.CategoryType')}
          name={'categoryId'}
          rules={[
            {
              required: true,
              message: t('Errors.SelectCategoryType'),
            },
          ]}
        >
          <Select>
            {state.categories.map((x, i) => (
              <Select.Option key={i} value={x.id}>
                {i18n.language === 'en' ? x?.name_en : x?.name_tr}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={'isPoint'}
          label={t('QuizManagement.IsPoint')}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name={'isActive'}
          label={t('Active')}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Form>
      <Button type="primary" onClick={onClickNext}>
        {t('QuizManagement.GoToQuestions')}
        <RightCircleOutlined />
      </Button>
    </div>
  );
};

export default Quiz;
