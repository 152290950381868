import React, { useState, useEffect } from 'react';
import { Button, Space, Table } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import teamApi from '../../services/teamApi';
import { Link } from 'react-router-dom';
const TeamManagement = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('TeamManagement.TeamName'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('TeamManagement.TeamOwner'),
      dataIndex: 'owner',
      key: 'owner',
      render: (_, rec) => _.username,
    },
    {
      title: t('TeamManagement.Quizzes'),
      dataIndex: 'id',
      key: 'createdAt',
      render: (val, rec) => rec.quizzes.length,
    },
    {
      title: t('TeamManagement.Users'),
      dataIndex: 'id',
      key: 'createdAt',
      render: (val, rec) => rec.users.length,
    },
    {
      title: t('Actions'),
      key: 'id',
      render: (val, record) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => onEdit(record)}
            style={{ color: '#2d27ff' }}
          />
          <DeleteOutlined
            onClick={() => onDelete(val.id)}
            style={{ color: '#ff0a6c' }}
          />
          <Link to={val.id}>
            <EyeOutlined style={{ color: '#2d27ff' }} />
          </Link>
        </Space>
      ),
    },
  ];
  const onDelete = async (id) => {
    try {
      await teamApi.deleteTeam(id);
      const res = await teamApi.getAll();
      setData((prev) => ({ ...prev, teams: res.data.rows }));
    } catch (error) {}
  };

  const onEdit = (record) => {
    // form.setFieldsValue({ ...record });
    // setData((prev) => ({
    //   ...prev,
    //   isEditing: true,
    //   id: record.id,
    //   popupTitle: t("UserManagement.EditUser"),
    //   openPopup: true,
    // }));
  };

  const [data, setData] = useState({
    loading: true,
    users: [],
    openPopup: false,
    isEditing: false,
    id: null,
    popupTitle: t('UserManagement.AddNewUser'),
  });

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await teamApi.getAll();
        setData((prev) => ({ ...prev, teams: res.data.rows }));
      } catch (error) {
      } finally {
        setData((prev) => ({ ...prev, loading: false }));
      }
    };
    getUsers();
  }, []);

  const openPopup = () => {
    setData((prev) => ({
      ...prev,
      popupTitle: t('UserManagement.AddNewUser'),
      openPopup: true,
    }));
  };
  return (
    <div>
      <div className="page-header">
        <Link key={1} to={'add-new-team'}>
          <Button
            key={1}
            icon={<PlusCircleOutlined />}
            type="primary"
            onClick={openPopup}
          >
            {t('TeamManagement.AddNewTeam')}
          </Button>
        </Link>
      </div>

      <Table
        bordered
        rowKey="id"
        loading={data.loading}
        columns={columns}
        dataSource={data.teams}
      />
    </div>
  );
};

export default TeamManagement;
