import React from 'react';
import { Layout, Dropdown, Button } from 'antd';
import Sidebar from '../sider/sidebar';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GlobalOutlined } from '@ant-design/icons';
import Breadcrumb from '../breadcrumb/breadcrumb';
const { Header, Content, Footer } = Layout;

const Dashboard = () => {
  const { i18n } = useTranslation();
  const menu = [
    {
      key: 'en',
      label: 'en',
    },
    {
      key: 'tr',
      label: 'tr',
    },
  ];
  const onClickLanguage = ({ key }) => {
    i18n.changeLanguage(key);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar />
      <Layout>
        <Header
          className="site-layout-sub-header-background"
          style={{
            padding: '0px 16px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Breadcrumb />
            <Dropdown
              menu={{ items: menu, onClick: onClickLanguage }}
              placement="bottomLeft"
            >
              <Button
                icon={<GlobalOutlined />}
                style={{ width: 80 }}
                type="outline"
              >
                {i18n.resolvedLanguage}
              </Button>
            </Dropdown>
          </div>
        </Header>
        <Content
          style={{
            margin: '24px 16px 0',
          }}
        >
          <div
            className="site-layout-background"
            style={{
              padding: 24,
              minHeight: '80vh',
              overflow: 'auto',
            }}
          >
            <Outlet />
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          Taria Admin Panel
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
