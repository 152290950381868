//Modules
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  questionTypes: [],
  quizTypes: [],
  categoryTypes: [],
  userTypes: [],
};

export const typesSlice = createSlice({
  name: 'types',
  initialState,
  reducers: {
    setQuestionTypes: (state, { payload }) => {
      state.questionTypes = payload;
    },
    setQuizTypes: (state, { payload }) => {
      state.quizTypes = payload;
    },
    setCategoryTypes: (state, { payload }) => {
      state.categoryTypes = payload;
    },
    setUserTypes: (state, { payload }) => {
      state.userTypes = payload;
    },
  },
});

export const {
  setQuestionTypes,
  setQuizTypes,
  setCategoryTypes,
  setUserTypes,
} = typesSlice.actions;

export default typesSlice.reducer;
