import React, { useState } from 'react';
import './style.css';
import { Descriptions, Collapse, Divider, Button } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import quizApi from '../../../services/quizApi';
import { useSelector } from 'react-redux';
const { Panel } = Collapse;
const Save = ({ quiz }) => {
  const { t, i18n } = useTranslation();
  const quizTypesData = useSelector((state) => state.typesReducer.quizTypes);
  const categoryTypesData = useSelector(
    (state) => state.typesReducer.categoryTypes
  );

  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const quizCategoryName = categoryTypesData.find(
    (x) => x.id === quiz.categoryId
  )?.[i18n.language === 'en' ? 'name_en' : 'name_tr'];

  const onSave = async () => {
    setLoading(true);
    try {
      await quizApi.addQuiz(quiz);
      navigate('/dashboard/quiz-management');
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="save-container">
      <div>
        <Descriptions
          title={t('QuizManagement.QuizDetail')}
          bordered
          column={{
            md: 4,
            sm: 1,
            xs: 1,
          }}
        >
          <Descriptions.Item label={t('Title')} span={{ md: 2 }}>
            {quiz.title}
          </Descriptions.Item>
          <Descriptions.Item
            label={t('QuizManagement.CategoryType')}
            span={{ md: 2 }}
          >
            {quizCategoryName}
          </Descriptions.Item>
          <Descriptions.Item
            label={t('QuizManagement.QuizType')}
            span={{ md: 2 }}
          >
            {quizTypesData.find((x) => x.id === quiz.quizTypeId)?.name}
          </Descriptions.Item>
          <Descriptions.Item
            label={t('QuizManagement.QuizOwner')}
            span={{ md: 2 }}
          >
            {quiz.ownerId ? quiz.ownerId : '-'}
          </Descriptions.Item>
          <Descriptions.Item label={t('Code')} span={{ md: 2 }}>
            {quiz.code ? quiz.code : '-'}
          </Descriptions.Item>
          <Descriptions.Item
            label={t('QuizManagement.IsPoint')}
            span={{ md: 2 }}
          >
            {quiz.isPoint ? 'Yes' : 'No'}
          </Descriptions.Item>
          <Descriptions.Item label={t('Active')} span={{ md: 2 }}>
            {quiz.isActive ? 'Yes' : 'No'}
          </Descriptions.Item>
          <Descriptions.Item
            label={t('QuizManagement.Questions')}
            span={{ md: 2 }}
          >
            <Collapse>
              {quiz.questions.map((question, i) => (
                <Panel header={question.title} key={i}>
                  {question.options.map((opt, ind) => (
                    <>
                      <div
                        key={ind}
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span>{opt.label}</span>
                        {opt.isCorrect ? (
                          <CheckCircleOutlined style={{ color: '#06c006' }} />
                        ) : (
                          <CloseCircleOutlined style={{ color: '#ed4061' }} />
                        )}
                      </div>
                      <Divider className="divider" />
                    </>
                  ))}
                </Panel>
              ))}
            </Collapse>
          </Descriptions.Item>
        </Descriptions>
        <div className="btn-container">
          <Button
            loading={loading}
            disabled={loading}
            type="primary"
            onClick={onSave}
          >
            {t('Save')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Save;
