export const endpoints = {
  auth: {
    login: 'auth/login',
  },
  userManagement: {
    users: 'users',
  },
  quizManagement: {
    quiz: 'quizzes',
  },
  teamManagement: {
    team: 'teams',
  },
  categoryManagement: {
    category: 'categories',
  },
  usertypeManagement: {
    usertypes: 'userTypes',
  },
  questiontypeManagement: {
    questiontypes: 'question-types',
  },
  quiztypeManagement: {
    quiztypes: 'quiz-types',
  },
};

export const quizTypes = {
  1: 'Default',
  2: 'Public',
  3: 'Private',
};

export const categoryTypes = {
  1: 'Siyaset',
  2: 'Spor',
  3: 'Eğlence',
  4: 'Matematik',
  5: 'Coğrafya',
  6: 'Oyun',
  7: 'Diğer',
};

export const userTypes = {
  admin: 10,
  restricted: 20,
  premium: 30,
  free: 40,
};
