import React, { useEffect, useState } from 'react';
import { Table, Space, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  PlusCircleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import quizApi from '../../services/quizApi';
import { Link } from 'react-router-dom/dist';
import { useSelector } from 'react-redux';
const QuizManagement = () => {
  const { t, i18n } = useTranslation();
  const quizTypesData = useSelector((state) => state.typesReducer.quizTypes);
  const categoryTypesData = useSelector(
    (state) => state.typesReducer.categoryTypes
  );

  const navigate = useNavigate();
  const [state, setState] = useState({
    quizzes: [],
    loading: true,
  });
  const onDelete = async (id) => {
    try {
      await quizApi.deleteQuiz(id);
      await quizApi.getAll();
    } catch (error) {}
  };
  const onEdit = async (quiz) => {
    navigate('edit-quiz', { state: quiz });
  };
  const columns = [
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'name',
    },
    {
      title: t('QuizManagement.QuizType'),
      dataIndex: 'quizType',
      key: 'quizType',
      filters: quizTypesData.map((x) => ({ text: x.name, value: x.id })),
      onFilter: (value, record) => record.quizTypeId === value,
      render: (val) => val.name,
    },
    {
      title: t('QuizManagement.CategoryType'),
      dataIndex: 'categoryId',
      key: 'categoryType',
      filters: categoryTypesData.map((x) => ({
        text: i18n.language === 'en' ? x?.name_en : x?.name_tr,
        value: x.id,
      })),
      onFilter: (value, record) => record.categoryId === value,
      render: (val) =>
        categoryTypesData.find((x) => x.id === val)?.[
          i18n.language === 'en' ? 'name_en' : 'name_tr'
        ],
    },
    {
      title: t('Language'),
      dataIndex: 'language',
      key: 'language',
    },
    {
      title: t('QuizManagement.IsPoint'),
      dataIndex: 'isPoint',
      key: 'isPoint',
      align: 'center',
      render: (_, rec) =>
        _ ? (
          <CheckOutlined style={{ color: '#50C878' }} />
        ) : (
          <CloseOutlined style={{ color: '#ff0a6c' }} />
        ),
    },
    {
      title: t('Active'),
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      render: (_, rec) =>
        _ ? (
          <CheckOutlined style={{ color: '#50C878' }} />
        ) : (
          <CloseOutlined style={{ color: '#ff0a6c' }} />
        ),
    },
    {
      title: t('QuizManagement.Questions'),
      dataIndex: 'questions',
      key: 'numberOfQuestions',
      render: (_, rec) => rec.questions.length,
    },
    {
      title: t('QuizManagement.Solved'),
      dataIndex: 'numberOfSolved',
      key: 'solved',
      render: () => 0,
    },
    {
      title: t('Actions'),
      key: 'id',
      render: (_) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => onEdit(_)}
            style={{ color: '#2d27ff', cursor: 'pointer' }}
          />
          <DeleteOutlined
            onClick={() => onDelete(_.id)}
            style={{ color: '#ff0a6c', cursor: 'pointer' }}
          />
          <Link to={_.id}>
            <EyeOutlined style={{ color: '#2d27ff' }} />
          </Link>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const getQuizzes = async () => {
      try {
        const res = await quizApi.getAll();
        setState((prev) => ({ ...prev, quizzes: res.data.rows }));
      } catch (error) {
      } finally {
        setState((prev) => ({ ...prev, loading: false }));
      }
    };
    getQuizzes();
  }, []);

  return (
    <div>
      <div className="page-header">
        <Link key={1} to={'add-new-quiz'}>
          <Button icon={<PlusCircleOutlined />} type="primary">
            {t('QuizManagement.AddNewQuiz')}
          </Button>
        </Link>
      </div>
      <Table
        bordered
        rowKey="id"
        loading={state.loading}
        columns={columns}
        dataSource={state.quizzes}
      />
    </div>
  );
};

export default QuizManagement;
