import axios from 'axios';
import tokenService from '../utils/tokenService';

const api = axios.create({
  baseURL: 'http://159.69.150.52:9001/',
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  let token = tokenService.getLocalAccessToken();

  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export default api;
