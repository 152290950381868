import React, { useState, useEffect } from 'react';
import { Button, Space, Table, Modal, Form, Input, Switch } from 'antd';
import categoryApi from '../../services/categoryApi';
import './category-management.css';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const CategoryManagement = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('name_en'),
      dataIndex: 'name_en',
      key: 'name_en',
      //render: (text) => <a>{text}</a>,
    },
    {
      title: t('name_tr'),
      dataIndex: 'name_tr',
      key: 'name_tr',
      //render: (text) => <a>{text}</a>,
    },
    {
      title: t('Active'),
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      render: (_, rec) =>
        _ ? (
          <CheckOutlined style={{ color: '#50C878' }} />
        ) : (
          <CloseOutlined style={{ color: '#ff0a6c' }} />
        ),
    },
    {
      title: t('MainCategory'),
      dataIndex: 'isDefault',
      key: 'isDefault',
      align: 'center',
      render: (_, rec) =>
        _ ? (
          <CheckOutlined style={{ color: '#50C878' }} />
        ) : (
          <CloseOutlined style={{ color: '#ff0a6c' }} />
        ),
    },
    {
      title: t('Popular'),
      dataIndex: 'isPopular',
      key: 'isPopular',
      align: 'center',
      render: (_, rec) =>
        _ ? (
          <CheckOutlined style={{ color: '#50C878' }} />
        ) : (
          <CloseOutlined style={{ color: '#ff0a6c' }} />
        ),
    },
    {
      title: t('Actions'),
      key: 'id',
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => onEdit(record)}
            style={{ color: '#2d27ff' }}
          />
          <DeleteOutlined
            onClick={() => onDelete(record.id)}
            style={{ color: '#ff0a6c' }}
          />{' '}
        </Space>
      ),
    },
  ];

  const onDelete = async (id) => {
    try {
      await categoryApi.deleteCategory(id);
      const res = await categoryApi.getAll();
      setData((prev) => ({ ...prev, categories: res.data.rows }));
    } catch (error) {}
  };

  const onEdit = (record) => {
    form.setFieldsValue({ ...record });
    setData((prev) => ({
      ...prev,
      isEditing: true,
      id: record.id,
      popupTitle: t('UserManagement.EditUser'),
      openPopup: true,
    }));
  };

  const [form] = Form.useForm();

  const [data, setData] = useState({
    loading: true,
    categories: [],
    openPopup: false,
    isEditing: false,
    isCategoryAdding: false,
    id: null,
    popupTitle: t('CategoryManagement.AddNewCategory'),
  });

  useEffect(() => {
    const getCategories = async () => {
      try {
        const res = await categoryApi.getAll();
        setData((prev) => ({ ...prev, categories: res.data.rows }));
      } catch (error) {
      } finally {
        setData((prev) => ({ ...prev, loading: false }));
      }
    };
    getCategories();
  }, []);

  const openPopup = () => {
    setData((prev) => ({
      ...prev,
      popupTitle: t('CategoryManagement.AddNewCategory'),
      openPopup: true,
    }));
  };

  const onFinish = async (payload) => {
    try {
      setData((prev) => ({ ...prev, isCategoryAdding: true }));
      if (data.isEditing) {
        await categoryApi.updateCategory(data.id, payload);
      } else {
        await categoryApi.addCategory(payload);
      }

      const res = await categoryApi.getAll();
      setData((prev) => ({ ...prev, categories: res.data.rows }));
    } catch (error) {
    } finally {
      setData((prev) => ({
        ...prev,
        openPopup: false,
        isEditing: false,
        isCategoryAdding: false,
      }));
      form.resetFields();
    }
  };

  return (
    <div>
      <div className="page-header">
        <Button
          key={1}
          icon={<PlusCircleOutlined />}
          type="primary"
          onClick={openPopup}
        >
          {t('CategoryManagement.AddNewCategory')}
        </Button>
      </div>
      <Table
        bordered
        rowKey="id"
        loading={data.loading}
        columns={columns}
        dataSource={data.categories}
      />
      <Modal
        title={data.popupTitle}
        centered
        open={data.openPopup}
        footer={null}
        onCancel={() => {
          form.resetFields();
          setData((prev) => ({
            ...prev,
            openPopup: false,
            isEditing: false,
          }));
        }}
      >
        <Form
          name="normal_login"
          className="login-form"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            name="name_en"
            rules={[
              {
                required: true,
                message: 'Please input english title!',
              },
            ]}
          >
            <Input placeholder={t('English Title')} size="large" />
          </Form.Item>
          <Form.Item
            name="name_tr"
            rules={[
              {
                required: true,
                message: 'Please input turkish title!',
              },
            ]}
          >
            <Input placeholder="Turkish title" size="large" />
          </Form.Item>
          <div className="switch-container">
            <Form.Item
              name={'isPopular'}
              label={t('Popular')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name={'isDefault'}
              label={t('MainCategory')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name={'isActive'}
              label={t('Active')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </div>
          <Form.Item style={{ textAlign: 'center' }}>
            <Button
              htmlType="submit"
              className="login-form-button"
              size="large"
              loading={data.isCategoryAdding}
              disabled={data.isCategoryAdding}
            >
              {t('Save')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CategoryManagement;
