class TokenService {
  getLocalAccessToken() {
    const jwtToken = localStorage.getItem("accessToken");
    return jwtToken;
  }

  updateLocalAccessToken(token) {
    let accessToken = localStorage.getItem("accessToken");
    accessToken = token;
    localStorage.setItem("accessToken", accessToken);
  }

  removeToken() {
    localStorage.clear();
  }
}
export default new TokenService();
