import React, { useState } from 'react';
import {
  UserOutlined,
  LogoutOutlined,
  DingdingOutlined,
  QuestionCircleOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Menu, Layout } from 'antd';
import { Link } from 'react-router-dom';
import { loginFailure } from '../../store/slices/authSlice';
import { useDispatch } from 'react-redux';
import './sider.css';
import tokenService from '../../utils/tokenService';
import { useTranslation } from 'react-i18next';
const { Sider } = Layout;

const Sidebar = () => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();

  const onLogout = () => {
    tokenService.removeToken();
    dispatch(loginFailure());
  };

  const items = [
    {
      label: <Link to={'user-management'}>{t('UserManagement.Name')}</Link>,
      key: '1',
      icon: <UserOutlined />,
    }, // remember to pass the key prop
    {
      label: <Link to={'quiz-management'}>{t('QuizManagement.Name')}</Link>,
      key: '2',
      icon: <QuestionCircleOutlined />,
    },
    {
      label: <Link to={'team-management'}>{t('TeamManagement.Name')}</Link>,
      key: '3',
      icon: <TeamOutlined />,
    },
    {
      label: (
        <Link to={'category-management'}>{t('CategoryManagement.Name')}</Link>
      ),
      key: '4',
      icon: <TeamOutlined />,
    },
    {
      label: (
        <Link to={'usertype-management'}>{t('UsertypeManagement.Name')}</Link>
      ),
      key: '5',
      icon: <TeamOutlined />,
    },
    {
      label: <Link onClick={onLogout}>{t('Logout')}</Link>,
      key: '6',
      icon: <LogoutOutlined />,
    },
  ];
  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="60"
      collapsible
      className="sider"
      onBreakpoint={(broken) => {
        //console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        setCollapsed(collapsed);
      }}
    >
      <div className="logo">
        <DingdingOutlined
          style={{ color: 'white', fontSize: collapsed ? '24px' : '48px' }}
        />
      </div>
      <Menu items={items} theme="dark" mode="inline" />
    </Sider>
  );
};

export default Sidebar;
